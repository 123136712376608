<template>
   <div id="wrapper">
      <vue-progress-bar></vue-progress-bar>
      <Topbar />
      <Sidebar />
      <Content />
   </div>
</template>

<script>
import Topbar from "./Topbar.vue";
import Sidebar from "./Sidebar.vue";
import Content from "./Content.vue";

export default {
   name: "Master",
   components: {
      Topbar,
      Sidebar,
      Content,
   },
};
</script>

<style>
</style>