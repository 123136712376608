<template>
  <div class="content-page">
    <div class="content">
      <router-view></router-view>
    </div>
    <!-- content -->

    <!-- Footer Start -->
    <footer class="footer" style="left: 250px;">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6">
            {{ currentMonth }}
            &copy; TIS-Internal-APP by <a href="#">Web Team(CID)</a>
          </div>
          <!-- <div class="col-md-6">
                        <div class="text-md-end footer-links d-none d-sm-block">
                            <a href="javascript:void(0);">About Us</a>
                            <a href="javascript:void(0);">Help</a>
                            <a href="javascript:void(0);">Contact Us</a>
                        </div>
                    </div> -->
        </div>
      </div>
    </footer>
    <!-- end Footer -->
  </div>
</template>

<script>
// import Footer from './Footer.vue'

export default {
  name: "Content",
  components: {
    // Footer
  },
  computed: {
    currentMonth() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style>
</style>